<template>
	<v-container fluid style="width: 80%">
		<v-layout>
			<v-flex>
				<!-- <h3 class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue">Leave</h3> -->
			</v-flex>
			<v-flex class="text-right my-2">
				<v-btn depressed class="custom-grey-border custom-bold-button" v-on:click="goBack">
					<v-icon small left>mdi-keyboard-backspace</v-icon>
					Back
				</v-btn>
			</v-flex>
		</v-layout>
		<perfect-scrollbar
			:options="{ suppressScrollX: true }"
			class="scroll custom-box-top-inner-shadow"
			style="max-height: 85vh; position: relative"
		>
			<v-card flat class="custom-setting-height custom-grey-border remove-border-radius">
				<v-card-title class="headline grey lighten-4">
					<v-row>
						<v-col md="6" class="py-0 my-auto">
							<h3 class="fw-700" style="margin: 3.5px 0px">
								Backlink Activity
								<!-- # -->
							</h3>
						</v-col>
						<template
							v-if="
								getPermission('backlink_activity_settings:create') &&
								AllowAnyOforGetRoleNType([
									'master',
									'admin',
									'manager',
									'super_admin',
									'team_lead',
									'ateam_lead',
								])
							"
						>
							<v-col md="6" class="text-right py-0">
								<v-btn
									color="red darken-4 mr-3"
									class="my-auto"
									depressed
									dark
									tile
									x-small
									min-height="26"
									v-on:click="AddActivity"
								>
									<v-icon size="26">mdi-plus</v-icon>
								</v-btn>
								<v-btn
									color="blue darken-4"
									class="white--text my-auto"
									depressed
									tile
									v-on:click="updateOrcreate"
									:disabled="pageLoading || !formValid"
								>
									<span class="font-size-16 font-weight-600">Save</span>
								</v-btn>
							</v-col>
						</template>
					</v-row>
				</v-card-title>
				<v-card-text class="p-6 font-size-16">
					<v-form
						ref="leave_category"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="updateOrcreate"
						style="height: calc(100vh - 245px); overflow-y: auto; overflow-x: hidden"
					>
						<!-- <label class="input-label">Activity Type</label> -->
						<v-row v-for="(type, index) in activitylist" :key="type.id">
							<v-col
								:md="
									AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
										? 11
										: 12
								"
								class=""
							>
								<v-text-field
									type="text"
									hide-details
									outlined
									class="textCapitalize"
									:readonly="
										type.title == 'backlink activity' ||
										!getPermission('backlink_activity_settings:update') ||
										AllowAnyOforGetRoleNType(['on_page', 'off_page']) ||
										Boolean(+checkAdminOrManager())
									"
									placeholder="Activity Type"
									v-model="type.title"
									:rules="[vrules.required(type.title, 'Activity Type')]"
									:class="{
										required: !type.title,
									}"
									@keydown.enter.prevent="
										() => {
											index + 1 === activitylist.length && AddActivity();
										}
									"
									@change="(e) => updateActivity(e, index)"
								>
								</v-text-field>
							</v-col>
							<!-- <v-col md="5" class="pb-4">
								<label class="input-label">Max Days</label>
								<v-text-field
									hide-details
									outlined
									type="Number"
									placeholder="Max Days"
									v-model="type.maxdays"
									v-on:keyup="updateDays(type.maxdays, type)"
									min="0"
									max="999"
									:rules="[vrules.required(type.maxdays, 'Max Days')]"
									:class="{
										required: !type.maxdays,
									}"
								>
								</v-text-field>
							</v-col> -->
							<v-col
								md="1"
								v-if="
									getPermission('backlink_activity_settings:delete') &&
									AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
								"
								class=""
							>
								<v-tooltip top content-class="custom-top-tooltip">
									<template v-slot:activator="{ on, attrs }">
										<v-icon
											v-on:click="deleteCommon(type.id, index)"
											color="deep-orange"
											v-bind="attrs"
											v-on="on"
											>mdi-delete</v-icon
										>
									</template>
									<span>Click here to delete</span>
								</v-tooltip>
							</v-col>
						</v-row>
						<template v-if="activitylist.length < 1">
							<p class="m-0 row-not-found text-center">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no backlink activity at the moment.
							</p>
						</template>
					</v-form>
				</v-card-text>
			</v-card>
		</perfect-scrollbar>
	</v-container>
</template>

<script>
import { POST, GET, DELETE } from "@/core/services/store/request.module";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import MainMixin from "@/core/mixins/main.mixin.js";

// import { map } from "lodash";
export default {
	name: "backlink-activity",
	mixins: [MainMixin],
	data() {
		return {
			customerId: 0,
			formValid: true,
			pageLoading: false,
			activitylist: [],
			setTitleIndex: null,
		};
	},
	watch: {
		"$route.query": {
			deep: true,
			handler(query) {
				if (query && query.tab == "backlink_activity") {
					this.getActivity();
				}
			},
		},
	},
	methods: {
		updateActivity(event, index) {
			this.activitylist[index].title = event;
			this.activitylist[index].value = event;
		},
		updateOrcreate() {
			const formErrors = this.validateForm(this.$refs["leave_category"]);
			this.$refs["leave_category"].validate();
			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}
			if (!this.$refs["leave_category"].validate()) {
				return false;
			}
			let presetnvalue = false;
			for (let i = 0; i < this.activitylist.length; i++) {
				let values = this.activitylist.filter((v) => v.value == this.activitylist[i].value);
				let size = values.length;
				if (size > 1) {
					presetnvalue = true;
					break;
				}
			}
			if (presetnvalue == true) {
				this.$store.commit(SET_ERROR, [
					{
						model: true,
						message: "Error! Backlink activity already exists.",
					},
				]);
				return false;
			}
			this.$store
				.dispatch(POST, {
					url: "create-activity",
					data: { activity: this.activitylist },
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Backlink created successfully.",
						},
					]);
					this.getActivity();
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateDays(days, typedata) {
			let decimals = days - Math.floor(days);
			if (decimals > 0) {
				typedata.maxdays = Math.floor(days) + 0.5;
			} else {
				typedata.maxdays = Math.floor(days);
			}
		},
		AddActivity() {
			this.activitylist.push({
				// activity: null,
				title: null,
				value: null,
			});
		},
		deleteCommon(activityid, index) {
			if (activityid) {
				this.deleteBacklinkActivity(activityid, index);
			} else {
				this.deleteActivity(index);
			}
		},
		deleteActivity(index) {
			if (this.activitylist.length > 1) {
				this.activitylist.splice(index, 1);
			}
		},
		deleteBacklinkActivity(activityid, index) {
			this.$store
				.dispatch(DELETE, {
					url: "delete-activity?id=" + activityid,
				})
				.then(() => {
					if (this.activitylist.length > 0) {
						this.activitylist.splice(index, 1);
					}
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Deleted ! Backlink activity successfully.",
						},
					]);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getActivity() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET, {
					url: "list-activity",
				})
				.then((data) => {
					this.activitylist = data.data.map((v) => {
						return { title: v.activity, value: v.activity, id: v.id };
					});
					// this.activitylist = map(this.activitylist, (row) => {
					// 	console.log(row.activity, "row");
					// 	return {...row, activity: row.activity.replace("_", " ")};
					// });
				})
				.catch((error) => {
					this.logError(error);
					this.goBack();
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	/* getStaffSetting() {
        
        this.$store
          .dispatch(GET, { url: "setting/engineer" })
          .then(({ data }) => {
            this.setting = data;
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.pageLoading = false;
          });
      },
      updateStaffSetting() {
        
        this.pageLoading = true;
        this.$store
          .dispatch(PATCH, { url: "setting/engineer", data: this.setting })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.pageLoading = false;
          });
      },*/
	mounted() {
		this.getActivity();
	},
	beforeMount() {
		this.customerId = this.$route.params.id;
	},
};
</script>
